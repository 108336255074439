import React, { useState } from "react";
import { Login } from "./LoginData";
import Cookies from "js-cookie";

const App = () => {
  const [Id, setId] = useState("");
  const [Password, setPassword] = useState("");

  const Login_Post = async () => {
    try {
      const params = {
        id: Id,
        password: Password,
      };

      const response = await Login(params);
      console.log(response);

      // 세션 쿠키로 설정 (expires 옵션 제거)
      Cookies.set("id", Id);
      Cookies.set("cpCode", response.cpCode);
      Cookies.set("token", response.token);
      Cookies.set("permission", response.permission);

      // 로그인 성공 시 페이지 이동
      document.location.href = `https://pessems.com/${response.cpCode}`;
    } catch (error) {
      console.error("로그인 실패: ", error);
      if (error.response.status !== 200) {
        window.alert("접속계정을 다시 확인하시길 바랍니다.");
      }
      return false;
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      Login_Post();
    }
  };

  return (
    <main>
      <div className="Login_Wrap">
        <div className="Login_Form">
          <div className="Inner">
            <div className="Logo" style={{ textAlign: "center" }}>
              <img src={process.env.PUBLIC_URL + `/img/LOGIN/NS_Logo-ver1.png`} alt="" style={{ margin: "0 auto" }} />
              {/* <p>KOREA EAST-WEST POWER Secondary</p> */}
            </div>
            <div className="Login_Data">
              <div className="INP_Container ID">
                <img src={process.env.PUBLIC_URL + `/img/LOGIN/ID.png`} alt="" />
                <input type="text" className="L_INP Id" placeholder="ID" value={Id} onChange={(e) => setId(e.target.value)} onKeyDown={handleKeyDown} />
              </div>
              <div className="INP_Container PW">
                <img src={process.env.PUBLIC_URL + `/img/LOGIN/PW.png`} alt="" />
                <input type="password" className="L_INP Pw" placeholder="PASSWORD" value={Password} onChange={(e) => setPassword(e.target.value)} onKeyDown={handleKeyDown} />
              </div>
            </div>
            <button className="Login_BTN" onClick={Login_Post} type="summit">
              LOGIN
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default App;
